/* eslint-disable indent */
import React, {
    useState, useCallback, useEffect,
  } from 'react';
import moment from 'moment';
  import { Button, Paper, TextField } from '@mui/material';
  import { makeStyles } from '@mui/styles';
  import { useTheme } from '@mui/material/styles';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { useDispatch, useSelector } from 'react-redux';
  import DeviceList from './DeviceList';
  // import BottomMenu from '../common/components/BottomMenu';
  import StatusCard from '../common/components/StatusCard';
  import { devicesActions } from '../store';
  import usePersistedState from '../common/util/usePersistedState';
  import EventsDrawer from './EventsDrawer';
  import useFilter from './useFilter';
  import MainToolbar from './MainToolbar';
  import MainMap from './MainMap';
  import { useAttributePreference } from '../common/util/preferences';

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
    },
    sidebar: {
      pointerEvents: 'none',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        position: 'fixed',
        left: 0,
        top: 0,
        height: `calc(100% - ${theme.spacing(3)})`,
        width: theme.dimensions.drawerWidthDesktop,
        margin: theme.spacing(1.5),
        zIndex: 3,
      },
      [theme.breakpoints.down('md')]: {
        height: '100%',
        width: '100%',
      },
    },
    header: {
      pointerEvents: 'auto',
      zIndex: 6,
    },
    footer: {
      pointerEvents: 'auto',
      zIndex: 5,
    },
    middle: {
      flex: 1,
      display: 'grid',
    },
    contentMap: {
      pointerEvents: 'auto',
      gridArea: '1 / 1',
    },
    contentList: {
      pointerEvents: 'auto',
      gridArea: '1 / 1',
      zIndex: 4,
    },
  }));

  const VehicleDetails = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [customDevice, setCustomDevice] = useState(null);

    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    const mapOnSelect = useAttributePreference('mapOnSelect', true);

    const selectedDeviceId = useSelector((state) => state.devices.selectedId);
    const positions = useSelector((state) => state.session.positions);
    const [filteredPositions, setFilteredPositions] = useState([]);
    const selectedPosition = filteredPositions.find((position) => selectedDeviceId && position.deviceId === selectedDeviceId);

    const [filteredDevices, setFilteredDevices] = useState([]);
    const [keyword, setKeyword] = useState('');
    const [filter, setFilter] = usePersistedState('filter', {
      statuses: [],
      groups: [],
    });
    const [filterSort, setFilterSort] = usePersistedState('filterSort', '');
    const [filterMap, setFilterMap] = usePersistedState('filterMap', false);
    const [devicesOpen, setDevicesOpen] = useState(desktop);
    const [eventsOpen, setEventsOpen] = useState(false);
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

    const onEventsClick = useCallback(() => setEventsOpen(true), [setEventsOpen]);

    useEffect(() => {
      if (!desktop && mapOnSelect && selectedDeviceId) {
        setDevicesOpen(false);
      }
    }, [desktop, mapOnSelect, selectedDeviceId]);

    useEffect(() => {
      if (selectedDeviceId && selectedDeviceId !== customDevice) {
        setCustomDevice(selectedDeviceId);
      }
    }, [selectedDeviceId]);

    useEffect(() => {
      if (filteredDevices?.length > 0) {
        dispatch(devicesActions.selectId(filteredDevices[0].id));
      }
    }, [filteredDevices]);

    useFilter(keyword, filter, filterSort, filterMap, positions, setFilteredDevices, setFilteredPositions);

    return (
      <div className={classes.root}>
        {desktop && (
          <MainMap
            filteredPositions={filteredPositions}
            selectedPosition={selectedPosition}
            onEventsClick={onEventsClick}
            customDevice={customDevice}
            toDate={toDate}
          />
        )}
        <div className={classes.sidebar}>
          <Paper square elevation={3} className={classes.header}>
            <MainToolbar
              filteredDevices={filteredDevices}
              devicesOpen={devicesOpen}
              setDevicesOpen={setDevicesOpen}
              keyword={keyword}
              setKeyword={setKeyword}
              filter={filter}
              setFilter={setFilter}
              filterSort={filterSort}
              setFilterSort={setFilterSort}
              filterMap={filterMap}
              setFilterMap={setFilterMap}
              showAddDevice={false}
            />
          </Paper>
          <div className={classes.middle}>
            {!desktop && (
              <div className={classes.contentMap}>
                <MainMap
                  filteredPositions={filteredPositions}
                  selectedPosition={selectedPosition}
                  onEventsClick={onEventsClick}
                  customDevice={customDevice}
                />
              </div>
            )}
            <Paper square className={classes.contentList} style={devicesOpen ? {} : { visibility: 'hidden' }}>
              <div style={{ marginTop: '10px', marginLeft: '5px', marginBottom: '0px', display: 'flex' }}>
                <TextField
                  label="Date Filter"
                  type="date"
                  value={toDate}
                  onChange={(e) => { setToDate(e.target.value); }}
                />
                <Button size="small" className={classes.button} onClick={() => { setToDate(moment().format('YYYY-MM-DD')); }}>
                  Clear
                </Button>
              </div>
              <DeviceList devices={filteredDevices} viewPage />
            </Paper>
          </div>
          {/* {desktop && (
            <div className={classes.footer}>
              <BottomMenu />
            </div>
          )} */}
        </div>
        <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
        {selectedDeviceId && (
          <StatusCard
            cardAction={false}
            deviceId={selectedDeviceId}
            position={selectedPosition}
            onClose={() => dispatch(devicesActions.selectId(null))}
            desktopPadding={theme.dimensions.drawerWidthDesktop}
          />
        )}
      </div>
    );
  };

  export default VehicleDetails;
