/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import MapView from '../map/core/MapView';
import MapSelectedDevice from '../map/main/MapSelectedDevice';
import MapAccuracy from '../map/main/MapAccuracy';
import MapGeofence from '../map/MapGeofence';
import MapCurrentLocation from '../map/MapCurrentLocation';
import PoiMap from '../map/main/PoiMap';
import MapPadding from '../map/MapPadding';
import { devicesActions } from '../store';
import MapDefaultCamera from '../map/main/MapDefaultCamera';
import MapLiveRoutes from '../map/main/MapLiveRoutes';
import MapPositions from '../map/MapPositions';
import MapOverlay from '../map/overlay/MapOverlay';
import MapGeocoder from '../map/geocoder/MapGeocoder';
import MapScale from '../map/MapScale';
import MapRoutePath from '../map/MapRoutePath';
import MapMarkers from '../map/MapMarkers';
import { useTranslation } from '../common/components/LocalizationProvider';

// import MapNotification from '../map/notification/MapNotification';
// import useFeatures from '../common/util/useFeatures';

const MainMap = ({ filteredPositions, selectedPosition, customDevice, toDate }) => {
  const t = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [positions, setPositions] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [kmlData, setKmlData] = useState(null);

  const changeDevice = async (deviceId, from, to) => {
    if (deviceId) {
      const query = new URLSearchParams({ deviceId, from, to });
      const response = await fetch(`/api/positions?${query.toString()}`);
      if (response.ok) {
        const positions = await response.json();
        setPositions(positions);
        if (!positions.length) {
          throw Error(t('sharedNoData'));
        }
      } else {
        throw Error(await response.text());
      }
    }
  };

  useEffect(() => {
    if (kmlData) {
      console.log('kml Data', kmlData);
      let startPoint = {};
      let endPoint = {};
      if (kmlData.features.length === 1) {
        const [startLng, startLat] = kmlData.features[0].geometry.coordinates[0];
        const [endLng, endLat] = kmlData.features[0].geometry.coordinates[kmlData.features[0].geometry.coordinates.length - 1];
        startPoint = { latitude: startLat, longitude: startLng }; // title: 'Start Point'
        endPoint = { latitude: endLat, longitude: endLng };
      } else if (kmlData.features.length > 1) {
        const [startLng, startLat] = kmlData.features[0].geometry.coordinates;
        const [endLng, endLat] = kmlData.features[2].geometry.coordinates;
        startPoint = { latitude: startLat, longitude: startLng }; // title: 'Start Point'
        endPoint = { latitude: endLat, longitude: endLng };
      }
      setMarkers([startPoint, endPoint]);
    }
  }, [kmlData]);

  useEffect(() => {
    let selectedFrom = moment().startOf('day').toISOString();
    let selectedTo = moment().endOf('day').toISOString();
    if (toDate) {
      selectedFrom = moment(toDate, 'YYYY-MM-DD').startOf('day').toISOString();
      selectedTo = moment(toDate, 'YYYY-MM-DD').endOf('day').toISOString();
    }

    changeDevice(customDevice, selectedFrom, selectedTo);
  }, [customDevice, selectedPosition, toDate]);

  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  // const eventsAvailable = useSelector((state) => !!state.events.items.length);

  // const features = useFeatures();

  const onMarkerClick = useCallback((_, deviceId) => {
    dispatch(devicesActions.selectId(deviceId));
  }, [dispatch]);

  return (
    <>
      <MapView>
        <MapOverlay />
        <MapGeofence />
        <MapRoutePath positions={positions} forceColor="#66bb6a" />
        <MapAccuracy positions={filteredPositions} />
        <MapLiveRoutes />
        <MapPositions
          positions={filteredPositions}
          onClick={onMarkerClick}
          selectedPosition={selectedPosition}
          showStatus
        />
        <MapDefaultCamera />
        <MapSelectedDevice />
        <PoiMap setKmlData={setKmlData} />
        <MapMarkers markers={markers} />
      </MapView>
      <MapScale />
      <MapCurrentLocation />
      <MapGeocoder />
      {/* {!features.disableEvents && (
        <MapNotification enabled={eventsAvailable} onClick={onEventsClick} />
      )} */}
      {desktop && (
        <MapPadding left={parseInt(theme.dimensions.drawerWidthDesktop, 10)} />
      )}
    </>
  );
};

export default MainMap;
